import React from 'react'
import Card from './Cards'
import useStyles from './Styles'

import { ResearchReportCard } from 'src/types/components/shared/atom'

interface Props {
  zenMode: boolean
  content: ResearchReportCard[]
  cardsHeader?: string
}
function ResearchReport({ zenMode, content, cardsHeader }: Props) {
  const { classes } = useStyles()
  return (
    <div className={classes.outerContainer}>
      <div className={`${classes.innerContainer} ${zenMode && classes.zenBg}`}>
        <Card
          cardDetails={content}
          zenMode={zenMode}
          cardsHeader={cardsHeader ? cardsHeader : ''}
        />
      </div>
    </div>
  )
}

export default ResearchReport
