import { makeStyles } from 'tss-react/mui'
import { lato } from '@styles/fonts'
import Noise from 'src/assets/images/campaigns/noise.png'

import { nunito_sans } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  outerContainer: {
    height: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-3.8rem',
    position: 'relative',
    zIndex: '222',
    [theme.breakpoints.down('md')]: {
      marginTop: '-2rem',
    },
  },

  innerContainer: {
    maxWidth: theme.maxWidth,
    background: `url(${Noise.src}) white`,
    borderRadius: '60px',
    padding: theme.spacing(15, 16, 22),
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10, 4, 15),
      borderRadius: '40px',
      marginBottom: theme.spacing(14),
    },
  },
  title: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '72px',
    textAlign: 'start',
    textTransform: 'uppercase',
    lineHeight: '84px',
    color: theme.custom.blackColor,
    padding: theme.spacing(3, 0, 12),
    width: '68%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '48px',
      lineHeight: '55px',
    },
  },
  cardTitle: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '36px',
    textAlign: 'start',
    textTransform: 'uppercase',
    lineHeight: '49px',
    color: theme.custom.blackColor,
    padding: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
  },
  imageResearchCard: {
    objectFit: 'cover',
  },
  cardDesc: {
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'start',
    lineHeight: '19px',
    color: theme.custom.blackColor,
    padding: theme.spacing(3, 0, 2),
    opacity: '.7',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  cardWrapper: {
    img: {
      cursor: 'pointer',
      width: '100%',
      maxHeight: '336px',
      borderRadius: '30px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '230px',
        borderRadius: '20px',
      },
    },
    a: {
      textDecoration: 'none',
    },
  },

  learnMoreBtn: {
    background: 'white',
    color: 'black',
    textAlign: 'center',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  btnWrapper: {
    width: '100%',
    // margin: 'auto',
    marginTop: theme.spacing(4),
  },
  zenBg: {
    backgroundImage: 'none',
  },
}))

export default useStyles
