import React, { useReducer } from 'react'
import { Card, CardContent, Box, Typography } from '@mui/material'
import Image, { StaticImageData } from 'next/image'
import Play from 'src/assets/svgs/icons/play'
import { checkSingular, kFormatter } from 'src/utils'
import useStyles from './VideoStyle'

interface CardProps {
  img?: StaticImageData | string
  videoID: string
  title: string
  views: number
  onClickHandler?: () => void
}

interface State {
  viewsCount: number | null
  switchToHQ: boolean
}

type Action =
  | { type: 'SET_VIEWS_COUNT'; payload: number }
  | { type: 'SWITCH_TO_HQ' }

const initialState: State = {
  viewsCount: null,
  switchToHQ: false,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_VIEWS_COUNT':
      return { ...state, viewsCount: action.payload }
    case 'SWITCH_TO_HQ':
      return { ...state, switchToHQ: true }
    default:
      return state
  }
}

const VideoCard: React.FC<CardProps> = ({
  title,
  videoID,
  views,
  onClickHandler,
}) => {
  const { classes } = useStyles()
  const [{ viewsCount, switchToHQ }, dispatch] = useReducer(
    reducer,
    initialState
  )
  return (
    <Card className={classes.cardContainer} onClick={onClickHandler}>
      <Box className={classes.videoWrapper}>
        <div className={classes.outerBox}>
          <div className={classes.innerBox}>
            <Play />
          </div>
        </div>
        <Image
          src={
            !switchToHQ
              ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
              : `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`
          }
          alt={title}
          aria-label={title}
          width={320}
          height={450}
          onError={() => dispatch({ type: 'SWITCH_TO_HQ' })}
          className={classes.cardImage}
        />
      </Box>

      <CardContent className={classes.cardContentContainer}>
        <Typography className={classes.cardText}>{title}</Typography>
        <Typography className={classes.viewsText}>{`${
          viewsCount ? kFormatter(viewsCount) : kFormatter(views)
        } 
        ${
          viewsCount
            ? checkSingular('VIEW', 'VIEWS', viewsCount)
            : checkSingular('VIEW', 'VIEWS', views)
        }
        `}</Typography>
      </CardContent>
    </Card>
  )
}

export default React.memo(VideoCard)
