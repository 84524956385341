/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Box, Grid } from '@mui/material'
import SearchResultCard from 'src/components/shared/modules/articleCard/SearchResultCard'
import isEmpty from 'lodash.isempty'
import type { SearchArticlesRes } from 'src/types'
import ResearchReport from 'src/components/pages/researchReport/ResearchReport'
import { ResearchReportCard } from 'src/types/components/shared/atom'
import useStyles from './styles'
import VideoCard from '../videoCard/VideoCard'
import { useRouter } from 'next/router'
import UsePagination from 'src/components/shared/atoms/pagination/Pagination'
import clsx from 'clsx'
import { useGetFavouriteArticleIdByUserQuery } from 'src/store/ApiCall/articleAPI'
import { getCookie } from 'cookies-next'
import TopPosts from '../topPosts'
import { NeedASafeSpaceI } from 'src/types/store'

// data-insights-object-id="${hit.objectID}"
// data-insights-position="${arrayIndex + 1 + page * hitsPerPage}"
// data-insights-query-id="${queryID}"

interface PropType {
  articlesData: SearchArticlesRes[] | []
  articlePage: number
  articlePageHandler: (current: number | null) => void
  dtlOriginalData?: any //TODO: update types
  researchPaperData?: any //TODO: update types
  searchMode?: boolean
  resultsOnly?: boolean
  zenMode?: boolean
  queryID?: string | undefined
  styles?: string
  pagesCount?: number
  selectedDropDownValue?: string
  query?: string
  topPostData?: NeedASafeSpaceI
  queryId?: string | undefined
  showPagination?: boolean
}

const SearchResultContainer: React.FC<PropType> = ({
  articlesData,
  articlePage,
  articlePageHandler,
  dtlOriginalData,
  researchPaperData,
  searchMode = true,
  resultsOnly = false,
  zenMode = false,
  queryID = undefined,
  styles,
  pagesCount = 1,
  selectedDropDownValue,
  query,
  topPostData,
  showPagination,
}: PropType) => {
  console.log(showPagination, 'showPagination')
  const { classes } = useStyles()
  const router = useRouter()
  const [researchCard, setResearchCard] = React.useState<ResearchReportCard[]>()
  const [carouselData, setCarouselData] = React.useState<any[]>()
  const isBrowser = typeof window === 'object' ? true : false
  const lang = getCookie('lang')
  let idxName = 'search_articles'

  if (lang == 'es-MX') {
    idxName = 'mx_search_articles'
  }

  const indexName = idxName
  const [loggedInUser, setLoggedInUser] = React.useState<number>(-1)
  const [articlesListData, setArticlesListData] =
    React.useState<SearchArticlesRes[]>()

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON =
        typeof window === 'object' ? localStorage.getItem('dtl_token') : null
      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  const { data } = useGetFavouriteArticleIdByUserQuery(loggedInUser)

  React.useEffect(() => {
    if (dtlOriginalData) {
      const processedData = dtlOriginalData?.map((item: any) => {
        return {
          img: item?.thumbnail?.url ? item.thumbnail.url : '',
          videoID: item.videoID,
          views: item.viewCount ? item.viewCount : 0,
          title: item.title || '',
        }
      })
      setCarouselData(processedData)
    }
  }, [dtlOriginalData])

  React.useEffect(() => {
    if (researchPaperData) {
      const processedData = researchPaperData?.map((item: any) => ({
        title: item?.heading,
        desc: item?.description,
        img: item?.image?.url || '',
        btnData: {
          content: item?.button.content,
          href: item?.button.href ? item?.button.href : '#',
        },
        report: item?.externalLink
          ? item?.externalLink
          : item?.attributes?.reportPdf?.data[0]?.attributes.url,
        externalLink: Boolean(item?.externalLink),
      }))
      setResearchCard(processedData)
    }
  }, [researchPaperData])

  React.useEffect(() => {
    let newArticlesData = []
    if (data && data.length) {
      newArticlesData =
        articlesData && articlesData.length
          ? articlesData.map((curr) => {
              if (data.find((el) => el == curr.id)) {
                return {
                  ...curr,
                  isLiked: true,
                }
              } else {
                return {
                  ...curr,
                  isLiked: false,
                }
              }
            })
          : []
    } else {
      newArticlesData =
        articlesData && articlesData.length
          ? articlesData.map((curr) => {
              return {
                ...curr,
                isLiked: false,
              }
            })
          : []
    }
    setArticlesListData(newArticlesData)
  }, [data, articlesData])
  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        spacing={4}
        className={clsx(classes.innerContainer, styles && styles)}
      >
        {!isEmpty(articlesData) ? (
          <>
            {!resultsOnly ? (
              <Grid container item md={12} className={classes.textContainer}>
                <h2 className={classes.articleHeading}>
                  {lang === 'En'
                    ? 'Related Articles'
                    : 'Artículos relacionados'}
                </h2>
              </Grid>
            ) : null}
            <Grid
              id="hits"
              container
              data-insights-index={indexName}
              item
              md={12}
              spacing={4}
            >
              {articlesListData?.map((item, index) => (
                <Grid
                  key={item.id}
                  className="hit"
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  data-insights-object-id={item?.id}
                  data-insights-position={index + 1 + articlePage * 21}
                  data-insights-query-id={queryID}
                >
                  <SearchResultCard
                    data={item}
                    zenMode={zenMode}
                    queryID={queryID}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              sx={{ margin: '50px 0px' }}
            >
              {showPagination ? (
                <UsePagination
                  pageCount={pagesCount}
                  handleClick={articlePageHandler}
                  page={articlePage}
                />
              ) : (
                ''
              )}
            </Grid>
          </>
        ) : null}
        <Grid container className={classes.gtlOriginalContainer}>
          {carouselData && !isEmpty(carouselData) ? (
            <Grid container className={classes.dtlOriginalInner}>
              <Grid container item md={12} className={classes.textContainer}>
                <h2 className={classes.articleHeading}>DTL ORIGINAL</h2>
              </Grid>
              <Grid container item md={12}>
                {carouselData.map((el, index) => (
                  <Grid
                    item
                    key={index}
                    margin="0 2rem 1rem 0"
                    mt={{ xs: 4, sm: 6, md: 9, lg: 12 }}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{ cursor: 'pointer' }}
                  >
                    <VideoCard
                      {...el}
                      onClickHandler={() => router.push('/dtl-originals')}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        {isBrowser && lang === 'En' ? (
          <Grid
            container
            item
            spacing={4}
            className={classes.researchResultContainer}
          >
            {!isEmpty(researchCard) && researchCard ? (
              <ResearchReport content={researchCard} zenMode={zenMode} />
            ) : null}
          </Grid>
        ) : (
          ''
        )}
      </Grid>

      <Box mb={16}>
        {topPostData &&
        (selectedDropDownValue == 'all' ||
          selectedDropDownValue == 'community-threads') ? (
          <TopPosts
            content={topPostData}
            zenMode={zenMode}
            community={true}
            search={query ? true : false}
            searchQuery={query}
          />
        ) : (
          ''
        )}
      </Box>
      {searchMode &&
        selectedDropDownValue !== 'community-threads' &&
        isEmpty(articlesData) &&
        isEmpty(carouselData) &&
        isEmpty(researchPaperData) && (
          <Grid className={classes.nothingFoundContainer}>
            <h2>No Result Found</h2>
          </Grid>
        )}
    </Grid>
  )
}

export default SearchResultContainer
