import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
import { pxToRem } from '@styles/function'

const useStyles = makeStyles()((theme) => ({
  cardContainer: {
    background: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
    maxWidth: 'auto',
    margin: 'auto',
  },
  cardImage: {
    width: '100%',
    height: 'auto',
    minHeight: '460px',
    borderRadius: '20px',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      minHeight: '324px',
    },
  },
  cardContentContainer: {
    padding: 0,
  },
  cardText: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: pxToRem(20),
    fontWeight: '800',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4.2),
    lineHeight: pxToRem(29),
    color: '#000000',
  },
  viewsText: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    textAlign: 'start',
    letterSpacing: ' 0.02em',
    textTransform: 'uppercase',
    color: '#818181',
  },
  cardBtnContainer: {
    padding: 0,
    borderRadius: '32px',
    overflow: 'hidden',
  },
  videoWrapper: {
    position: 'relative',
    display: 'flex',
  },

  outerBox: {
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: '78px',
    height: '78px',
    background: 'rgba(255, 255, 255, 0.22)',
  },
  innerBox: {
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: '62px',
    height: '62px',
    background: 'rgba(255, 255, 255, 0.22);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles
