import React from 'react'
import useStyles from './Styles'
import { Grid, Tooltip, Typography, Box } from '@mui/material'
import Image from 'next/image'
import { ButtonComponent } from 'src/components/shared/atoms'
import { useRouter } from 'next/router'

interface Props {
  cardDetails: {
    title: string
    desc: string
    img: string
    btnData: { content: string; href: string }
    report: string
    externalLink: boolean
  }[]
  zenMode: boolean
  cardsHeader: string
}

function Cards({ cardDetails, zenMode, cardsHeader }: Props) {
  const { classes } = useStyles()
  const router = useRouter()

  return (
    <div>
      <Typography className={classes.title}>{cardsHeader}</Typography>
      <Grid
        container
        columnSpacing={{ lg: 10, md: 6, sm: 0, xs: 0 }}
        rowSpacing={{ lg: 12, md: 10, sm: 10, xs: 10 }}
        justifyContent="center"
        className={classes.cardWrapper}
        alignItems="stretch"
      >
        {cardDetails.map((card, idx) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              key={idx}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                {!zenMode && (
                  <Tooltip title="view report detail">
                    <Image
                      src={card.img}
                      alt="image"
                      width={676}
                      height={336}
                      onClick={() => {
                        if (card.externalLink) {
                          router.push(card.report)
                          return
                        }
                        router.push({
                          pathname: `/research-report/${card.title}`,
                        })
                        return
                      }}
                      className={classes.imageResearchCard}
                    />
                  </Tooltip>
                )}
                <Typography className={classes.cardTitle}>
                  {card.title}
                </Typography>
                <Typography className={classes.cardDesc}>
                  {card.desc}
                </Typography>
              </Box>

              <a
                href={card.report ? card.report : '#'}
                download
                target="_blank"
                rel="noreferrer"
              >
                <ButtonComponent
                  type="text"
                  text={card.btnData.content}
                  className={classes.learnMoreBtn}
                  gradient
                  wrapperClass={classes.btnWrapper}
                />
              </a>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default Cards
