import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
  },
  innerContainer: {
    width: '100%',
    height: 'auto',
    maxWidth: theme.maxWidth,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(4, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    },
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  articleHeading: {
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
  nothingFoundContainer: {
    width: '100%',
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gtlOriginalContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dtlOriginalInner: {
    width: '100%',
    maxWidth: theme.maxWidth,
    padding: theme.spacing(2, 4),
  },
  researchReportContainer: {
    margin: theme.spacing(12, 2, 2, 2),
    padding: theme.spacing(2),
  },
  researchResultContainer: {
    margin: theme.spacing(8, 0, 0),
  },
}))

export default useStyles
